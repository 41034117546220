import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid2,
  Card,
  CardContent,
  Typography,
  CircularProgress
} from '@mui/material';
import { Link, useSearchParams } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

function ContentCount() {
  const [searchParams] = useSearchParams();
  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(dayjs(searchParams.get('startDate')) || dayjs().subtract(31, 'day'));
  const [endDate, setEndDate] = useState(dayjs(searchParams.get('endDate')) || dayjs().subtract(1, 'day'));
  const author = searchParams.get('author') || 'Michael Friscolanti'
  const site = searchParams.get('site') || 'www.sootoday.com'

  const columns = [
    {
      field: 'storyId',
      headerName: 'Story ID',
      hide: true, // Hides this column
    },
    {
      field: 'category',
      headerName: 'Category',
      hide: true, // Hides this column
    },
    {
      field: 'title',
      headerName: 'Title',
      flex: 3,
      renderCell: (params) => (
        <Link to={`/platform-design/content-count/story-details?storyId=${params.row.storyId}&title=${params.value}&category=${params.row.category}&startDate=${dayjs(startDate).get('year')}-${dayjs(startDate).get('month') + 1}-${dayjs(startDate).get('date')}&endDate=${dayjs(endDate).get('year')}-${dayjs(endDate).get('month') + 1}-${dayjs(endDate).get('date')}&site=${site}`}>
          {params.value}
        </Link>
      )
    },
    { field: 'views', headerName: 'Views', flex: 1.5 }
  ];

  useEffect(() => {
    // Fetch interest groups when an audience is selected
    setLoading(true)
    if (author) {
      fetch(`/api/author-count?authorName=${author}&site=${site}&startDate=${dayjs(startDate).get('year')}-${dayjs(startDate).get('month') + 1}-${dayjs(startDate).get('date')}&endDate=${dayjs(endDate).get('year')}-${dayjs(endDate).get('month') + 1}-${dayjs(endDate).get('date')}`)
        .then((res) => res.json())
        .then((data) => {
          console.log('Author Lookup response:', data);
          setResults(data);
          setLoading(false)
        })
        .catch((error) => console.error('Error fetching author:', error));
    } else {

    }
  }, [author, startDate, endDate, site]);

  return (
    <Container maxWidth="md">
      <Grid2
        container
        spacing={3}
      >
        <Grid2 size={{ xs: 12, sm: 12, md: 6 }}>&nbsp;</Grid2>
        <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
          <DatePicker
            fullWidth
            label="Start Date"
            value={startDate}
            onChange={(newDate) => {
              setStartDate(newDate)
              console.log(startDate)
            }}
          />
        </Grid2>
        <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
          <DatePicker
            fullWidth
            label="End Date"
            value={endDate}
            onChange={(newDate) => {
              setEndDate(newDate)
              console.log(startDate)
            }} />
        </Grid2>
      </Grid2>
      {loading ? (
        <CircularProgress style={{ display: 'block', margin: '20px auto' }} />
      ) : (
        results ? (
          <>
            <Typography variant="h4" style={{ marginTop: "20px" }}>Author Breakdown: {author}</Typography>
            <Grid2 container spacing={3} marginTop={3}>
              <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
                <Card variant="outlined">
                  <CardContent>
                    <Typography variant="caption">Pageviews</Typography>
                    <Typography variant="h4">{Number(results.totalViews).toLocaleString()}</Typography>
                  </CardContent>
                </Card>
              </Grid2>
              <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
                <Card variant="outlined">
                  <CardContent>
                    <Typography variant="caption">Story Count</Typography>
                    <Typography variant="h4">{results.uniqueCount}</Typography>
                  </CardContent>
                </Card>
              </Grid2>
              <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
                <Card variant="outlined">
                  <CardContent>
                    <Typography variant="caption">Stories per Day</Typography>
                    <Typography variant="h4">{Math.round(results.storiesPerDay * 100) / 100}</Typography>
                  </CardContent>
                </Card>
              </Grid2>
              <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
                <Card variant="outlined">
                  <CardContent>
                    <Typography variant="caption">Pages per story</Typography>
                    <Typography variant="h4">{Math.round(results.pagesPerStory * 100) / 100}</Typography>
                  </CardContent>
                </Card>
              </Grid2>
            </Grid2>
            <DataGrid style={{ marginTop: '20px', height: '400px' }} rows={results.authorResults} columns={columns} />
          </>
        ) : (
          <></>
        ))}
    </Container>
  );
}

export default ContentCount;
