import React from 'react';

import { Container, Typography } from '@mui/material';

const Home = () => {
  return (
    <Container maxWidth="md">
      <Typography>Home page coming soon!</Typography>
    </Container>
  );
};

export default Home;