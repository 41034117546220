import React, { useState, useEffect } from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  InputLabel,
  Select,
  MenuItem,
  Container,
  Typography,
  TextField,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
} from '@mui/material';
import Widget from '../components/widget';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import WidgetPreview from '../components/preview';

function EmailWidgets() {
  const [audiences, setAudiences] = useState([]);
  const [selectedAudience, setSelectedAudience] = useState('');
  const [interestGroups, setInterestGroups] = useState([]);
  const [widgetType, setWidgetType] = useState('Inline Widget');
  const [headline, setHeadline] = useState('Stay in the loop.');
  const [message, setMessage] = useState('Sign up for your free morning email newsletter with the latest local headlines.');
  const [logo, setLogo] = useState('https://www.vmcdn.ca/f/files/barrietoday/layout/logo_thestir.png');
  const [publicKey, setPublicKey] = useState('6LfWPp0UAAAAAIysca8s1A8t1EP5QqHEFkgGLOZz');
  const [callToAction, setCallToAction] = useState('Sign me up');
  const [formPlacement, setFormPlacement] = useState("1");

  const widgets = [{ id: 1, name: "Inline Widget" }, { id: 2, name: "Landing Page" }]

  const [selectedInterestGroups, setSelectedInterestGroups] = useState([]); // Primary
  const [selectedSecondaryInterestGroups, setSelectedSecondaryInterestGroups] = useState([]); // Secondary

  useEffect(() => {
    // Fetch audiences on component mount
    fetch('/api/audiences')
      .then((res) => res.json())
      .then((data) => {
        setAudiences(data || []);
      })
      .catch((error) => console.error('Error fetching audiences:', error));
  }, []);

  useEffect(() => {
    // Fetch interest groups when an audience is selected
    if (selectedAudience) {
      fetch(`/api/interest-groups?listId=${selectedAudience}`)
        .then((res) => res.json())
        .then((data) => {
          console.log('Interest Groups API response:', data);
          const interests = data.interestsData?.flatMap((item) => item.interests) || [];
          setInterestGroups(interests);
        })
        .catch((error) => console.error('Error fetching interest groups:', error));
    } else {
      setInterestGroups([]);
    }
  }, [selectedAudience]);

  const handleCheckboxChange = (event, group) => {
    if (event.target.checked) {
      setSelectedInterestGroups((prev) => [...prev, { id: group.id, name: group.name }]);
    } else {
      setSelectedInterestGroups((prev) =>
        prev.filter((selectedGroup) => selectedGroup.id !== group.id)
      );
    }
  };

  const handleSecondaryCheckboxChange = (event, group) => {
    if (event.target.checked) {
      setSelectedSecondaryInterestGroups((prev) => [...prev, { 
        id: group.id, 
        name: group.name, 
        message: 'Also sign me up to receive headlines to my inbox every afternoon.' 
      }]);
    } else {
      setSelectedSecondaryInterestGroups((prev) =>
        prev.filter((selectedGroup) => selectedGroup.id !== group.id)
      );
    }
  };

  const updateMessage = (newMessage, groupId) => {
    setSelectedSecondaryInterestGroups((prev) => 
      prev.map((item) =>
        item.id === groupId ? { ...item, message: newMessage } : item
      )
    )
  }

  return (
    <Container maxWidth="md">
      <Alert severity='info'>When adding multiple widgets to a page, please make sure you're overriding the form position option.</Alert>
      <FormControl fullWidth margin="normal">
        <InputLabel id="audience-select-label">Audience</InputLabel>
        <Select
          labelId="audience-select-label"
          value={selectedAudience}
          label="Audience"
          onChange={(e) => {
            setSelectedAudience(e.target.value);
            setSelectedInterestGroups([]); // Reset primary interest groups
            setSelectedSecondaryInterestGroups([]); // Reset secondary interest groups
          }}
        >
          {audiences.map((audience) => (
            <MenuItem key={audience.id} value={audience.id}>
              {audience.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>


      <FormControl fullWidth margin="normal">
        <InputLabel id="widget-select-label">Widget Type</InputLabel>
        <Select
          labelId="widget-select-label"
          value={widgetType}
          label="Widget Type"
          onChange={(e) => {
            setWidgetType(e.target.value);
          }}
        >
          {widgets.map((widget) => (
            <MenuItem key={widget.id} value={widget.name}>
              {widget.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography>Override Settings</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormControl fullWidth margin="normal">
            <TextField id="headline" label="Headline" variant="outlined" value={headline} onChange={(e) => {
              setHeadline(e.target.value)
            }} />
          </FormControl>

          <FormControl fullWidth margin="normal">
            <TextField id="message" label="Message" variant="outlined" value={message} onChange={(e) => {
              setMessage(e.target.value)
            }} />
          </FormControl>

          <FormControl fullWidth margin="normal">
            <TextField id="logo" label="Logo" variant="outlined" value={logo} onChange={(e) => {
              setLogo(e.target.value)
            }} />
          </FormControl>

          <FormControl fullWidth margin="normal">
            <TextField id="publicKey" value={publicKey} label="Public Key (Recaptcha)" variant="outlined" onChange={(e) => {
              setPublicKey(e.target.value)
            }} />
          </FormControl>

          <FormControl fullWidth margin="normal">
            <TextField id="cta" label="Call to Action" value={callToAction} variant="outlined" onChange={(e) => {
              setCallToAction(e.target.value)
            }} />
          </FormControl>

          <FormControl fullWidth margin="normal">
            <TextField id="formId" label="Form Position" value={formPlacement} variant="outlined" onChange={(e) => {
              setFormPlacement(e.target.value)
            }} />
          </FormControl>
          {selectedSecondaryInterestGroups.map((group, index) => (
            <FormControl fullWidth margin="normal">
              <TextField id={`checkbox-${index}`} label={`Checkbox Message ${index}`} value={group.message} variant="outlined" onChange={(e) => {
                updateMessage(e.target.value, group.id)
              }} />
            </FormControl>
          ))}
        </AccordionDetails>
      </Accordion>

      {/* Primary Interest Groups */}
      {interestGroups.length > 0 && (
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography>Interest Groups</Typography>
          </AccordionSummary>
          <AccordionDetails>

            <FormControl component="fieldset" fullWidth margin="normal">
              <FormLabel component="legend">Primary Interest Groups</FormLabel>
              <FormGroup>
                {interestGroups.map((group) => (
                  <FormControlLabel
                    key={`primary-${group.id}`}
                    control={
                      <Checkbox
                        checked={selectedInterestGroups.some(
                          (selectedGroup) => selectedGroup.id === group.id
                        )}
                        onChange={(e) => handleCheckboxChange(e, group)}
                        name={group.name}
                      />
                    }
                    label={group.name}
                  />
                ))}
              </FormGroup>
            </FormControl>
            <FormControl component="fieldset" fullWidth margin="normal">
              <FormLabel component="legend">Secondary Interest Groups</FormLabel>
              <FormGroup>
                {interestGroups.map((group) => (
                  <FormControlLabel
                    key={`secondary-${group.id}`}
                    control={
                      <Checkbox
                        checked={selectedSecondaryInterestGroups.some(
                          (selectedGroup) => selectedGroup.id === group.id
                        )}
                        onChange={(e) => handleSecondaryCheckboxChange(e, group)}
                        name={group.name}
                      />
                    }
                    label={group.name}
                  />
                ))}
              </FormGroup>
            </FormControl>

          </AccordionDetails>
        </Accordion>
      )}

      {/* Display Selected Interest Groups */}
      {selectedInterestGroups.length > 0 && (
        <>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel3-header"
            >
              <Typography>Widget Code</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Widget
                groups={selectedInterestGroups}
                secondaryGroups={selectedSecondaryInterestGroups}
                type={widgetType}
                headline={headline}
                message={message}
                callToAction={callToAction}
                publicKey={publicKey}
                logo={logo}
                formPlacement={formPlacement}
                preview={false}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4-content"
              id="panel4-header"
            >
              <Typography>Widget Preview</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <WidgetPreview
                groups={selectedInterestGroups}
                secondaryGroups={selectedSecondaryInterestGroups}
                type={widgetType}
                headline={headline}
                message={message}
                callToAction={callToAction}
                publicKey={publicKey}
                logo={logo}
                formPlacement={formPlacement}
                preview={true}
              />
            </AccordionDetails>
          </Accordion>
        </>
      )}

    </Container>
  );
}

export default EmailWidgets;
