import React, { useState, useEffect } from 'react';
import {
    Container,
    Grid2,
    Card,
    CardContent,
    Typography,
    CircularProgress,
    Autocomplete,
    TextField,
    Chip,
    LinearProgress,
    Tooltip
} from '@mui/material';
import { Link, useSearchParams } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

function SiteDashboard() {
    const [results, setResults] = useState(null);
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState(false);
    const [hostnameLoading, setHostnameLoading] = useState(true)
    const [startDate, setStartDate] = useState(dayjs().subtract(31, 'day'));
    const [endDate, setEndDate] = useState(dayjs().subtract(1, 'day'));
    const [hostnames, setHostnames] = useState([]);
    const [site, setSite] = useState(searchParams.get('site') || null);

    const categoryColumns = [
        { field: 'category', headerName: 'Category', flex: 2 },
        { field: 'views', headerName: 'Views', flex: 1.5 },
        { field: 'sessions', headerName: 'Sessions', flex: 1.5 }
    ];

    const contentTypeCategories = [
        { field: 'contentType', headerName: 'Content Type', flex: 2 },
        { field: 'views', headerName: 'Views', flex: 1.5 },
        { field: 'sessions', headerName: 'Sessions', flex: 1.5 }
    ];

    const authorColumns = [
        {
            field: 'author', headerName: 'Author', flex: 2, renderCell: (params) => (
                <Link to={`/platform-design/content-count?author=${params.value}&site=${site}&startDate=${dayjs(startDate).get('year')}-${dayjs(startDate).get('month') + 1}-${dayjs(startDate).get('date')}&endDate=${dayjs(endDate).get('year')}-${dayjs(endDate).get('month') + 1}-${dayjs(endDate).get('date')}`}>
                    {params.value}
                </Link>
            )
        },
        { field: 'views', headerName: 'Views', flex: 1.5 },
        { field: 'sessions', headerName: 'Sessions', flex: 1.5 }
    ];

    useEffect(() => {
        // Fetch audiences on component mount
        if (site) {
            setLoading(true)
            fetch(`/api/site-analytics?site=${site}&startDate=${dayjs(startDate).get('year')}-${dayjs(startDate).get('month') + 1}-${dayjs(startDate).get('date')}&endDate=${dayjs(endDate).get('year')}-${dayjs(endDate).get('month') + 1}-${dayjs(endDate).get('date')}`)
                .then((res) => res.json())
                .then((data) => {
                    setResults(data)
                    setLoading(false)
                })
                .catch((error) => console.error('Error fetching audiences:', error));
        } else {
            fetch(`/api/analytics-site-lookup`)
                .then((res) => res.json())
                .then((data) => {
                    let hostnameResult = []
                    data.hostnames.forEach((row => hostnameResult.push(row.hostname)))
                    setHostnames(hostnameResult || [])
                    setHostnameLoading(false)
                })
                .catch((error) => console.error('Error fetching audiences:', error));
        }
    }, [site, startDate, endDate]);

    return (
        <Container maxWidth="lg">
            <Grid2
                container
                spacing={3}
            >
                <Grid2 size={{ xs: 12, sm: 12, md: 6 }}>
                    <Autocomplete
                        disablePortal
                        renderInput={(params) => <TextField {...params} label="Site" />}
                        options={hostnames}
                        loading={hostnameLoading}
                        onInputChange={(e, value, reason) => { if (reason === "selectOption") { setSite(value) } }}
                    />
                </Grid2>
                <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
                    <DatePicker
                        fullWidth
                        label="Start Date"
                        value={startDate}
                        onChange={(newDate) => {
                            setStartDate(newDate)
                            console.log(startDate)
                        }}
                    />
                </Grid2>
                <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
                    <DatePicker
                        fullWidth
                        label="End Date"
                        value={endDate}
                        onChange={(newDate) => {
                            setEndDate(newDate)
                            console.log(startDate)
                        }} />
                </Grid2>
            </Grid2>
            {loading ? (
                <CircularProgress style={{ display: 'block', margin: '20px auto' }} />
            ) : (
                results ? (
                    <>
                        <Grid2 container spacing={3} marginTop={3}>
                            <Grid2 container spacing={3} size={{ xs: 12, sm: 6, md: 8 }}>
                                <Grid2 size={{ xs: 12, sm: 6, md: 6 }}>
                                    <Card variant="outlined">
                                        <CardContent>
                                            <Grid2 container spacing={0} >
                                                <Grid2 container direction="column"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    sx={{ mx: 'auto' }}
                                                >
                                                    <Typography variant="caption">Content Pageviews</Typography>
                                                    <Typography variant="h4">{Number(results.totalViews).toLocaleString()}</Typography>
                                                    {(results.viewsChange > 0) && (
                                                        <Chip color='success' variant="outlined" size="small" icon={<TrendingUpIcon />} label={`${Math.round(results.viewsChange * 100) / 100}%`} style={{ marginTop: "10px" }} />
                                                    )}
                                                    {(results.viewsChange < 0) && (
                                                        <Chip color='error' variant="outlined" size="small" icon={<TrendingDownIcon />} label={`${Math.round(results.viewsChange * 100) / 100}%`} style={{ marginTop: "10px" }} />
                                                    )}
                                                </Grid2>
                                            </Grid2>
                                        </CardContent>
                                    </Card>
                                </Grid2>
                                <Grid2 size={{ xs: 12, sm: 6, md: 6 }}>
                                    <Card variant="outlined">
                                        <CardContent>
                                            <Grid2 container spacing={0} >
                                                <Grid2 container direction="column"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    sx={{ mx: 'auto' }}
                                                >
                                                    <Typography variant="caption">Sessions</Typography>
                                                    <Typography variant="h4">{Number(results.totalSessions).toLocaleString()}</Typography>
                                                    {(results.sessionsChange > 0) && (
                                                        <Chip color='success' variant="outlined" size="small" icon={<TrendingUpIcon />} label={`${Math.round(results.sessionsChange * 100) / 100}%`} style={{ marginTop: "10px" }} />
                                                    )}
                                                    {(results.sessionsChange < 0) && (
                                                        <Chip color='error' variant="outlined" size="small" icon={<TrendingDownIcon />} label={`${Math.round(results.sessionsChange * 100) / 100}%`} style={{ marginTop: "10px" }} />
                                                    )}
                                                </Grid2>
                                            </Grid2>
                                        </CardContent>
                                    </Card>
                                </Grid2>
                                <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
                                    <Card variant="outlined">
                                        <CardContent>
                                            <Grid2 container spacing={0} >
                                                <Grid2 container direction="column"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    sx={{ mx: 'auto' }}
                                                >
                                                    <Typography variant="caption">Story Count</Typography>
                                                    <Typography variant="h4">{results.count}</Typography>
                                                    {(results.countChange > 0) && (
                                                        <Chip color='success' variant="outlined" size="small" icon={<TrendingUpIcon />} label={`${Math.round(results.countChange * 100) / 100}%`} style={{ marginTop: "10px" }} />
                                                    )}
                                                    {(results.countChange < 0) && (
                                                        <Chip color='error' variant="outlined" size="small" icon={<TrendingDownIcon />} label={`${Math.round(results.countChange * 100) / 100}%`} style={{ marginTop: "10px" }} />
                                                    )}
                                                </Grid2>
                                            </Grid2>
                                        </CardContent>
                                    </Card>
                                </Grid2>
                                <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
                                    <Card variant="outlined">
                                        <CardContent>
                                            <Grid2 container spacing={0} >
                                                <Grid2 container direction="column"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    sx={{ mx: 'auto' }}
                                                >
                                                    <Typography variant="caption">Pageviews per Story</Typography>
                                                    <Typography variant="h4">{Math.round(results.pagesPerStory * 100) / 100}</Typography>
                                                    {(results.pagesChange > 0) && (
                                                        <Chip color='success' variant="outlined" size="small" icon={<TrendingUpIcon />} label={`${Math.round(results.pagesChange * 100) / 100}%`} style={{ marginTop: "10px" }} />
                                                    )}
                                                    {(results.pagesChange < 0) && (
                                                        <Chip color='error' variant="outlined" size="small" icon={<TrendingDownIcon />} label={`${Math.round(results.pagesChange * 100) / 100}%`} style={{ marginTop: "10px" }} />
                                                    )}
                                                </Grid2>
                                            </Grid2>
                                        </CardContent>
                                    </Card>
                                </Grid2>
                                <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
                                    <Card variant="outlined">
                                        <CardContent>
                                            <Grid2 container spacing={0} >
                                                <Grid2 container direction="column"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    sx={{ mx: 'auto' }}
                                                >
                                                    <Typography variant="caption">Stories per Day</Typography>
                                                    <Typography variant="h4">{Math.round(results.storiesPerDay * 100) / 100}</Typography>
                                                    {(results.postsPerDayChange > 0) && (
                                                        <Chip color='success' variant="outlined" size="small" icon={<TrendingUpIcon />} label={`${Math.round(results.postsPerDayChange * 100) / 100}%`} style={{ marginTop: "10px" }} />
                                                    )}
                                                    {(results.postsPerDayChange < 0) && (
                                                        <Chip color='error' variant="outlined" size="small" icon={<TrendingDownIcon />} label={`${Math.round(results.postsPerDayChange * 100) / 100}%`} style={{ marginTop: "10px" }} />
                                                    )}
                                                </Grid2>
                                            </Grid2>
                                        </CardContent>
                                    </Card>
                                </Grid2>
                            </Grid2>
                            <Grid2 container spacing={3} size={{ xs: 12, sm: 6, md: 4 }}>
                                <Grid2 size={{ xs: 12, sm: 12, md: 12 }}>
                                    <Card variant="outlined">
                                        <CardContent>
                                            <Typography sx={{fontWeight: '800'}}>Cadence benchmark:</Typography>
                                            <LinearProgress 
                                            variant="determinate"
                                            color={
                                                ((results.count / results.networkAverageCount) * 100) > 80 ? "success" :  "error"
                                            }
                                            sx={{height: "20px", marginTop: "10px", borderRadius: "5px"}} 
                                            value={
                                                ((results.count / results.networkAverageCount) * 100) > 100 ? 100 : (results.count / results.networkAverageCount) * 100
                                            } />
                                            <Typography variant='caption'>{results.count} posts vs. the Village average of {Math.round(results.networkAverageCount)} posts.</Typography>
                                        </CardContent>
                                    </Card>
                                </Grid2>
                                <Grid2 size={{ xs: 12, sm: 12, md: 12 }}>
                                    <Card variant="outlined">
                                        <CardContent>
                                            <Typography sx={{ fontWeight: '800' }}>Your posting frequency:</Typography>
                                            <Tooltip title={`${Math.round(results.normalizedHistoricTotal)}`} arrow>
                                                <LinearProgress 
                                                variant="determinate"
                                                color={
                                                    ((results.count / results.normalizedHistoricTotal) * 100) > 80 ? "success" :  "error"
                                                }
                                                sx={{height: "20px", marginTop: "10px", borderRadius: "5px"}} 
                                                value={
                                                    ((results.count / results.normalizedHistoricTotal) * 100) > 100 ? 100 : (results.count / results.normalizedHistoricTotal) * 100
                                                } />
                                                </Tooltip>
                                            <Typography variant="caption">{results.count} posts published vs. your average of {Math.round(results.normalizedHistoricTotal)} posts.</Typography>
                                        </CardContent>
                                    </Card>
                                </Grid2>
                            </Grid2>
                        </Grid2>
                        <Typography variant="h4" style={{ marginTop: '20px' }}>Top Categories</Typography>
                        <DataGrid style={{ marginTop: '20px', height: '400px' }} rows={results.categoryResults} columns={categoryColumns} />

                        <Typography variant="h4" style={{ marginTop: '20px' }}>Top Authors</Typography>
                        <DataGrid style={{ marginTop: '20px', height: '400px' }} rows={results.authorResults} columns={authorColumns} />

                        <Typography variant="h4" style={{ marginTop: '20px' }}>Top Content Types</Typography>
                        <DataGrid style={{ marginTop: '20px', height: '400px' }} rows={results.contentTypeResults} columns={contentTypeCategories} />
                    </>
                ) : (
                    <></>
                )
            )}
        </Container >
    );
}

export default SiteDashboard;
