import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Layout from './layouts/dashboard';
import EmailWidgets from './pages/mailchimp-widgets';
import AdUnits from './pages/gam-builder';
import InterstitialBuilder from './pages/interstitial-builder';
import { GlobalStyles } from '@mui/material';
import BusinessDashboard from './pages/business-dashboard';
import Home from './pages/home';
import SignupForms from './pages/signup-forms';
import ContentCount from './pages/content-count';
import StoryDetails from './pages/story-details';
import SiteDashboard from './pages/site-breakdown';

const root = ReactDOM.createRoot(document.getElementById('root'));

const router = createBrowserRouter([
  {
    Component: App, // root layout route
    children: [
      {
        path: '/',
        Component: Layout,
        children: [
          {
            path: '',
            Component: Home
          },
          {
            path: '/platform-design/email-ads',
            Component: AdUnits
          },
          {
            path: '/client-services/interstitial-ads',
            Component: InterstitialBuilder
          },
          {
            path: '/client-services/business-dashboard',
            Component: BusinessDashboard
          },
          {
            path: '/platform-design/mailchimp-widgets',
            Component: EmailWidgets
          },
          {
            path: '/platform-design/signup-forms',
            Component: SignupForms
          },
          {
            path: '/platform-design/content-count',
            Component: ContentCount
          },
          {
            path: '/platform-design/content-count/story-details',
            Component: StoryDetails
          },
          {
            path: '/platform-design/analytics',
            Component: SiteDashboard
          },
          {
            path: '/business-dashboard',
            element: <Navigate to="/client-services/business-dashboard" />
          },
          {
            path: '/mailchimp-widgets',
            element: <Navigate to="/platform-design/mailchimp-widgets" />
          },
          {
            path: '/interstitial-ads',
            element: <Navigate to="/client-services/interstitial-ads" />
          },
          {
            path: '/email-ads',
            element: <Navigate to="/platform-design/email-ads" />
          },
          //{
          //  path: '*', // catch-all wildcard
          //  element: <Navigate to="/" replace /> // redirect to home or any other desired route
          //}
        ]
      }
    ]
  },
]);

root.render(
  <React.StrictMode>
      <GlobalStyles styles={{ pre: { whiteSpace: 'pre-wrap' } }} />
      <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
