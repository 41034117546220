import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid2,
  Card,
  CardContent,
  Typography,
  CircularProgress
} from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { PieChart } from '@mui/x-charts';

function StoryDetails() {
  const [results, setResults] = useState(null);
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const site = searchParams.get('site') || null;
  const storyId = searchParams.get('storyId') || null;
  const [startDate, setStartDate] = useState(dayjs(searchParams.get('startDate')) || dayjs().subtract(31, 'day'));
  const [endDate, setEndDate] = useState(dayjs(searchParams.get('endDate')) || dayjs().subtract(1, 'day'));
  const title = searchParams.get('title') || null;
  const category = searchParams.get('category') || null;

  const columns = [
    { field: 'channel', headerName: 'Channels', flex: 2 },
    { field: 'views', headerName: 'Views', flex: 1.5 },
    { field: 'sessions', headerName: 'Sessions', flex: 1.5 }
  ];

  useEffect(() => {
    // Fetch audiences on component mount
    if (storyId) {
      setLoading(true)
      fetch(`/api/story-details?storyId=${storyId}&site=${site}&startDate=${dayjs(startDate).get('year')}-${dayjs(startDate).get('month') + 1}-${dayjs(startDate).get('date')}&endDate=${dayjs(endDate).get('year')}-${dayjs(endDate).get('month') + 1}-${dayjs(endDate).get('date')}&site=${site}`)
        .then((res) => res.json())
        .then((data) => {
          setResults(data)
          setLoading(false)
        })
        .catch((error) => console.error('Error fetching audiences:', error));
    }
  }, [storyId, site, startDate, endDate]);

  return (
    <Container maxWidth="md">
      <Grid2
        container
        spacing={3}
      >
        <Grid2 size={{ xs: 12, sm: 12, md: 6 }}>&nbsp;</Grid2>
        <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
          <DatePicker
            fullWidth
            label="Start Date"
            value={startDate}
            onChange={(newDate) => {
              setStartDate(newDate)
              console.log(startDate)
            }}
          />
        </Grid2>
        <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
          <DatePicker
            fullWidth
            label="End Date"
            value={endDate}
            onChange={(newDate) => {
              setEndDate(newDate)
              console.log(startDate)
            }} />
        </Grid2>
      </Grid2>
      {loading ? (
        <CircularProgress style={{ display: 'block', margin: '20px auto' }} />
      ) : (
        results ? (
          <>
            <Typography variant="h4" gutterBottom style={{ marginTop: '20px' }}>
              {title}
            </Typography>
            <Typography gutterBottom>
              By: {results.results[0].author}
            </Typography>
            <Typography gutterBottom>
              Published in: {category}
            </Typography>
            <Grid2 container spacing={3} marginTop={3}>
              <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
                <Card variant="outlined">
                  <CardContent>
                    <Typography variant="caption">Pageviews</Typography>
                    <Typography variant="h4">{Number(results.totalViews).toLocaleString()}</Typography>
                  </CardContent>
                </Card>
              </Grid2>
              <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
                <Card variant="outlined">
                  <CardContent>
                    <Typography variant="caption">Sessions</Typography>
                    <Typography variant="h4">{Number(results.totalSessions).toLocaleString()}</Typography>
                  </CardContent>
                </Card>
              </Grid2>
            </Grid2>
            <PieChart 
              style={{ marginTop: ""}}
              series={[
                {
                  highlightScope: { fade: 'global', highlight: 'item' },
                  faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                  data: results.results.map(row => ({
                      id: row.id,
                      value: Number(row.sessions),
                      label: row.channel,
                    })
                  )
                }
              ]} 
              width={400} 
              height={200}
              slotProps={{ legend: { hidden: true }}}
            />
            <DataGrid style={{ marginTop: '20px', height: '400px' }} rows={results.results} columns={columns} />
          </>
        ) : (
          <></>
        )
      )}
    </Container >
  );
}

export default StoryDetails;
