import React, { useState, useEffect } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Container,
  Button
} from '@mui/material';
import { Link } from 'react-router-dom';

function SignupForms() {
  const [audiences, setAudiences] = useState([]);
  const [selectedAudience, setSelectedAudience] = useState('');
  const [resubscribeForms, setResubscribeForms] = useState([]);

  useEffect(() => {
    // Fetch audiences on component mount
    fetch('/api/audiences')
      .then((res) => res.json())
      .then((data) => {
        setAudiences(data || []);
      })
      .catch((error) => console.error('Error fetching audiences:', error));
  }, []);

  useEffect(() => {
    // Fetch interest groups when an audience is selected
    if (selectedAudience) {
      fetch(`/api/resubscribes?listId=${selectedAudience}`)
        .then((res) => res.json())
        .then((data) => {
          console.log('Signup Forms API response:', data);
          setResubscribeForms(data);
        })
        .catch((error) => console.error('Error fetching signup forms:', error));
    } else {

    }
  }, [selectedAudience]);


  return (
    <Container maxWidth="md">
      <FormControl fullWidth margin="normal">
        <InputLabel id="audience-select-label">Audience</InputLabel>
        <Select
          labelId="audience-select-label"
          value={selectedAudience}
          label="Audience"
          onChange={(e) => {
            setSelectedAudience(e.target.value);
          }}
        >
          {audiences.map((audience) => (
            <MenuItem key={audience.id} value={audience.id}>
              {audience.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {resubscribeForms.map((form) => (
        <Link to={form.signup_form_url} target='_blank'><Button>Signup Form</Button></Link>
      ))}
    </Container>
  );
}

export default SignupForms;
