import * as React from 'react';
import { AppProvider } from '@toolpad/core/react-router-dom';
import AdUnitsIcon from '@mui/icons-material/AdUnits';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import HighlightAltIcon from '@mui/icons-material/HighlightAlt';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import HomeIcon from '@mui/icons-material/Home';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import { Outlet } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ClerkProvider } from '@clerk/clerk-react';

const NAVIGATION = [
  {
    kind: 'header',
    title: 'Main items',
  },
  {
    title: 'Home',
    icon: <HomeIcon />,
  },
  {
    title: 'Platform & Design',
    segment: 'platform-design',
    icon: <DesignServicesIcon />,
    children: [
      {
        segment: 'email-ads',
        title: 'Email Ads',
        icon: <AdUnitsIcon />,
      },
      {
        segment: 'mailchimp-widgets',
        title: 'MailChimp Widgets',
        icon: <MailOutlineIcon />
      },
      {
        segment: 'signup-forms',
        title: 'Signup Forms',
        icon: <MailOutlineIcon />
      },
      {
        segment: 'analytics',
        title: 'Analytics Platform',
        icon: <AutoGraphIcon />
      }
    ]
  },
  {
    title: 'Client Services',
    segment: 'client-services',
    icon: <LoyaltyIcon />,
    children: [
      {
        segment: 'interstitial-ads',
        title: 'Interstitial Ads',
        icon: <HighlightAltIcon />,
      },
      {
        segment: 'business-dashboard',
        title: 'Business Dashboard',
        icon: <LeaderboardIcon />,
      },
    ]
  },
];

const BRANDING = {
  title: 'Village Media Tools',
};

const PUBLISHABLE_KEY = 'pk_live_Y2xlcmsudG9vbHMudmlsbGFnZW1lZGlhLmNhJA';

export default function App() {

  return (

    <ClerkProvider publishableKey={PUBLISHABLE_KEY} afterSignOutUrl="/">
      <AppProvider navigation={NAVIGATION} branding={BRANDING}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Outlet />
        </LocalizationProvider>
    </AppProvider>
    </ClerkProvider>
  );
}
